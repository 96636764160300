import { Stack, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import {
    Feedback,
    ThriveReset
} from '../../../../graphql/generated/autogenerated'
import { PostResetActionButton } from '../../../elements/PostResetScreen'
import { useThriveResetVideoLike } from '../../../../hooks/useResetLike'
import { useThriveFavoriteReset } from '../../../../hooks/useResetFavorite'
import { Routes } from '../../../../routes/routes'
import { ThriveResetParams } from '../../../../pages/ThriveReset/types'
import { getShareableOrigin } from '../../../../utils/url'
import { copyTextToClipboard } from '../../../../utils/clipboard'
import { autoCloseCopyTooltipTimeout } from '../../../elements/ShareResetPopup/constants'
import { CopiedTooltipStyled } from '../../../elements/ShareResetPopup/ShareResetPopupStyled'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { useResetFeedback } from '../../../../hooks/useResetFeedback'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { ResetFeatureFlags } from '../../../../config/features'

export type PostThriveResetActionsProps = {
    thriveReset: ThriveReset
    onReplay?: () => void
    onShared?: () => void
    onFavored?: (isChecked: boolean) => void
    onClapped?: (isChecked: boolean) => void
    onLiked?: () => void
    onDisliked?: () => void
}

const messages = defineMessages({
    replayAriaLabel: {
        defaultMessage: 'Replay',
        description: 'Post Reset replay button aria label'
    },
    favoriteAriaLabel: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button aria label'
    },
    clapAriaLabel: {
        defaultMessage: 'Clap',
        description: 'Post Reset clap button aria label'
    },
    shareAriaLabel: {
        defaultMessage: 'Share',
        description: 'Post Reset share button aria label'
    },
    copied: {
        defaultMessage: 'Copied!',
        description: 'Post Reset copy reset url to clipboard success message'
    },
    likeAriaLabel: {
        defaultMessage: 'Like',
        description: 'Post Reset like button aria label'
    },
    dislikeAriaLabel: {
        defaultMessage: 'Dislike',
        description: 'Post Reset dislike button aria label'
    },
    likeButtonTooltip: {
        defaultMessage: 'Show me more like this',
        description: 'Post Reset like button tooltip'
    },
    dislikeButtonTooltip: {
        defaultMessage: 'Show me less like this',
        description: 'Post Reset dislike button tooltip'
    },
    favoriteButtonTooltip: {
        defaultMessage: 'Favorite',
        description: 'Post Reset favorite button tooltip'
    },
    copyButtonTooltip: {
        defaultMessage: 'Copy link',
        description: 'Post Reset copy button tooltip'
    }
})

function PostThriveResetActions(
    props: PostThriveResetActionsProps
): JSX.Element {
    const { thriveReset, onReplay, onClapped, onFavored, onShared } = props

    const isResetFeedbackEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsResetFeedbackEnabled,
        false,
        true
    )

    const intl = useIntl()
    const theme = useTheme()

    const contentContainerRef = useRef<HTMLDivElement>(null)
    const copySuccessTooltipTimeout = useRef<NodeJS.Timeout | number>()
    const [isCopySuccessTooltipOpen, setIsCopySuccessTooltipOpen] =
        useState(false)

    const clearCopySuccessTimeout = useCallback(() => {
        if (copySuccessTooltipTimeout.current) {
            clearTimeout(copySuccessTooltipTimeout.current)
            copySuccessTooltipTimeout.current = null
        }
    }, [])

    const {
        createResetLike,
        deleteResetLike,
        isLoading: isLikeLoading
    } = useThriveResetVideoLike()

    const {
        createFavoriteReset,
        deleteFavoriteReset,
        isLoading: isFavoriteLoading
    } = useThriveFavoriteReset()

    const { updateFeedback, isLoading: isFeedbackLoading } = useResetFeedback()

    const isUpdating = isLikeLoading || isFavoriteLoading || isFeedbackLoading

    const isClapped = thriveReset?.likes.isLiked ?? false
    const isFavorite = thriveReset?.bookmarked ?? false
    const isInLibrary = location.pathname.startsWith(`${Routes.LearnReset}`)
    const isLiked = thriveReset?.latestFeedback === Feedback.Like ?? false
    const isDisliked = thriveReset?.latestFeedback === Feedback.Dislike ?? false

    const resetUrl = useMemo(() => {
        if (!thriveReset?.id) {
            return ''
        }

        const path = generatePath(
            isInLibrary
                ? `${Routes.Learn}${Routes.ThriveReset}`
                : Routes.ThriveReset,
            {
                id: thriveReset.id
            } as ThriveResetParams
        )
        const origin = getShareableOrigin()

        const url = new URL(path, origin)

        return url.toString()
    }, [isInLibrary, thriveReset.id])

    const handleClapClick = useCallback(() => {
        if (isUpdating || !thriveReset) {
            return
        }

        const isChecked = !thriveReset.likes.isLiked
        if (isChecked) {
            createResetLike(thriveReset)
        } else {
            deleteResetLike(thriveReset)
        }

        onClapped?.(isChecked)
    }, [createResetLike, deleteResetLike, isUpdating, thriveReset, onClapped])

    const handleFavoriteClick = useCallback(() => {
        if (isUpdating || !thriveReset) {
            return
        }

        const isChecked = !thriveReset.bookmarked
        if (isChecked) {
            createFavoriteReset(thriveReset)
        } else {
            deleteFavoriteReset(thriveReset)
        }

        onFavored?.(isChecked)
    }, [
        createFavoriteReset,
        deleteFavoriteReset,
        isUpdating,
        thriveReset,
        onFavored
    ])

    const handleShareClick = useCallback(async () => {
        clearCopySuccessTimeout()

        await copyTextToClipboard(resetUrl, {
            container: contentContainerRef.current
        })

        setIsCopySuccessTooltipOpen(true)

        copySuccessTooltipTimeout.current = setTimeout(() => {
            setIsCopySuccessTooltipOpen(false)
            clearCopySuccessTimeout()
        }, autoCloseCopyTooltipTimeout)

        onShared?.()
    }, [resetUrl, clearCopySuccessTimeout, onShared])

    const handleLikeClick = useCallback(async () => {
        if (thriveReset.latestFeedback !== Feedback.Like && !isUpdating) {
            await updateFeedback(Feedback.Like, thriveReset)
        }
    }, [updateFeedback, isUpdating, thriveReset])

    const handleDislikeClick = useCallback(async () => {
        if (thriveReset.latestFeedback !== Feedback.Dislike && !isUpdating) {
            await updateFeedback(Feedback.Dislike, thriveReset)
        }
    }, [updateFeedback, isUpdating, thriveReset])

    useEffect(() => {
        return () => {
            clearCopySuccessTimeout()
        }
    }, [clearCopySuccessTimeout])

    return (
        <Stack
            direction="row"
            spacing={{ xs: 1, md: 2 }}
            ref={contentContainerRef}
            data-testid="PostThriveResetActions"
        >
            {!isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        onClick={onReplay}
                        aria-label={intl.formatMessage(
                            messages.replayAriaLabel
                        )}
                        data-testid="ReplayButton"
                    >
                        <LeafIcon icon={'arrow-rotate-left'} />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isClapped}
                        isUpdating={isUpdating}
                        onClick={handleClapClick}
                        data-testid="ClapButton"
                        aria-label={intl.formatMessage(messages.clapAriaLabel)}
                    >
                        <LeafIcon
                            icon={'hands-clapping'}
                            iconStyle={isClapped ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            {isResetFeedbackEnabled && (
                <>
                    <PostResetActionButton
                        isPressed={isLiked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleLikeClick}
                        data-testid="LikeButton"
                        aria-label={intl.formatMessage(messages.likeAriaLabel)}
                        tooltip={intl.formatMessage(messages.likeButtonTooltip)}
                    >
                        <LeafIcon
                            icon={'thumbs-up'}
                            iconStyle={isLiked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                    <PostResetActionButton
                        isPressed={isDisliked}
                        isUpdating={isFeedbackLoading}
                        onClick={handleDislikeClick}
                        data-testid="DislikeButton"
                        aria-label={intl.formatMessage(
                            messages.dislikeAriaLabel
                        )}
                        tooltip={intl.formatMessage(
                            messages.dislikeButtonTooltip
                        )}
                    >
                        <LeafIcon
                            icon={'thumbs-down'}
                            iconStyle={isDisliked ? 'solid' : 'regular'}
                        />
                    </PostResetActionButton>
                </>
            )}

            <PostResetActionButton
                isPressed={isFavorite}
                isUpdating={isUpdating}
                onClick={handleFavoriteClick}
                data-testid="FavoriteButton"
                aria-label={intl.formatMessage(messages.favoriteAriaLabel)}
                tooltip={intl.formatMessage(messages.favoriteButtonTooltip)}
            >
                {isFavorite ? (
                    <LeafIcon
                        icon={'heart'}
                        iconStyle="solid"
                        data-testid="Favorite"
                    />
                ) : (
                    <LeafIcon icon={'heart'} data-testid="Favorite" />
                )}
            </PostResetActionButton>

            <CopiedTooltipStyled
                open={isCopySuccessTooltipOpen}
                title={intl.formatMessage(messages.copied)}
                placement="top"
                sx={{ fontSize: theme.typography.body1.fontSize }}
                describeChild
            >
                <PostResetActionButton
                    onClick={handleShareClick}
                    aria-label={intl.formatMessage(messages.shareAriaLabel)}
                    data-testid="ShareButton"
                    tooltip={intl.formatMessage(messages.copyButtonTooltip)}
                >
                    <LeafIcon icon={'link'} />
                </PostResetActionButton>
            </CopiedTooltipStyled>
        </Stack>
    )
}

export default React.memo(PostThriveResetActions)
