import {
    Button,
    Card,
    CardMedia,
    FormControlLabel,
    LinearProgress,
    Stack,
    Switch,
    Tooltip,
    useMediaQuery
} from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ResetFeatureFlags } from '../../../config/features'
import useIsResetAutoplayEnabled from '../../../hooks/useIsResetAutoplayEnabled'
import useTimer from '../../../hooks/useTimer'
import { autoplayNextResetDelayInSeconds } from './constants'

const messages = defineMessages({
    playResetLabel: {
        defaultMessage: 'Play reset',
        description: 'Play reset label'
    },
    autoPlayNextResetAriaLabel: {
        defaultMessage: 'Autoplay next reset',
        description: 'Autoplay next reset switch aria label'
    },
    autoPlayToggleOnTooltip: {
        defaultMessage: 'Autoplay is on',
        description: 'Autoplay toggle on tooltip'
    },
    autoPlayToggleOffTooltip: {
        defaultMessage: 'Autoplay is off',
        description: 'Autoplay toggle off tooltip'
    }
})

export type PostResetNextResetPreviewProps = {
    isOpen: boolean
    thumbnail: string
    name: string
    description: string
    onNext: (isAutoplay?: boolean) => void
}

function PostResetNextResetPreview(
    props: PostResetNextResetPreviewProps
): JSX.Element {
    const { isOpen, name, onNext, thumbnail, description } = props
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const toggleRef = React.useRef(null)

    const intl = useIntl()

    const isMobileView = useMediaQuery(breakpoints.down('sm'))

    const isResetAutoPlayFeatureEnabled = useIsFeatureEnabled(
        ResetFeatureFlags.IsResetAutoPlayEnabled,
        false,
        true
    )

    const [isResetAutoPlayEnabled, setIsResetAutoplayEnabled] =
        useIsResetAutoplayEnabled()

    const handleAutoPlay = useCallback(() => {
        onNext?.(true)
    }, [onNext])

    const handlePlayNext = useCallback(() => {
        onNext?.()
    }, [onNext])

    const {
        value: autoplayCountdown,
        start: startAutoplayCountdown,
        stop: stopAutoplayCountdown
    } = useTimer({
        from: 0,
        to: autoplayNextResetDelayInSeconds,
        step: 0.1,
        onCompleted: handleAutoPlay
    })

    const autoplayCountdownProgress = useMemo<number>(
        () =>
            isOpen
                ? (autoplayCountdown / autoplayNextResetDelayInSeconds) * 100
                : 0,
        [autoplayCountdown, isOpen]
    )

    useEffect(() => {
        if (isResetAutoPlayEnabled && isOpen) {
            toggleRef?.current?.focus()
            startAutoplayCountdown()
        }

        return () => {
            stopAutoplayCountdown()
        }
    }, [
        startAutoplayCountdown,
        stopAutoplayCountdown,
        isResetAutoPlayEnabled,
        isOpen
    ])

    const handleToggleResetAutoplay = useCallback(
        (event: React.ChangeEvent, checked: boolean) => {
            setIsResetAutoplayEnabled(checked)
        },
        [setIsResetAutoplayEnabled]
    )

    return (
        <Card
            sx={{
                height: isMobileView ? 'inherit' : '100%',
                width: isMobileView ? '100%' : 'inherit',
                minWidth: isMobileView ? '100%' : 450
            }}
        >
            <Stack padding={2} gap={2}>
                <Stack
                    direction={'row'}
                    gap={2}
                    sx={
                        !isMobileView && {
                            height: '100%'
                        }
                    }
                >
                    {thumbnail && (
                        <CardMedia
                            component="img"
                            sx={{
                                width: 150
                            }}
                            image={thumbnail}
                            alt={description}
                        />
                    )}

                    <Stack
                        direction={'column'}
                        alignItems={'start'}
                        justifyContent={'space-between'}
                        gap={3}
                    >
                        <Stack flex={'1 0 auto'} gap={1}>
                            {isResetAutoPlayFeatureEnabled &&
                            isResetAutoPlayEnabled ? (
                                <>
                                    <CoreTypography variant="h6">
                                        <FormattedMessage
                                            defaultMessage="Up next in a few seconds..."
                                            description="Post thrive reset watch next title"
                                        />
                                    </CoreTypography>
                                    <LinearProgress
                                        color="accent"
                                        variant="determinate"
                                        value={autoplayCountdownProgress}
                                    />
                                </>
                            ) : (
                                <>
                                    <CoreTypography
                                        variant="h6"
                                        sx={{
                                            mb:
                                                isResetAutoPlayFeatureEnabled &&
                                                !isResetAutoPlayEnabled
                                                    ? 1.5
                                                    : 'initial'
                                        }}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Up next..."
                                            description="Post thrive reset watch next title"
                                        />
                                    </CoreTypography>
                                </>
                            )}
                            <CoreTypography
                                data-testid="NextResetName"
                                variant="h5"
                            >
                                {name}
                            </CoreTypography>
                        </Stack>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePlayNext}
                            aria-label={formatMessage(messages.playResetLabel)}
                            data-testid="WatchNextButton"
                            startIcon={
                                <LeafIcon icon="play" fontSize="small" />
                            }
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Play"
                                    description="play button"
                                />
                            </CoreTypography>
                        </Button>
                    </Stack>
                </Stack>
                {isResetAutoPlayFeatureEnabled && (
                    <FormControlLabel
                        control={
                            <Tooltip
                                title={
                                    <CoreTypography variant="caption">
                                        {isResetAutoPlayEnabled
                                            ? intl.formatMessage(
                                                  messages.autoPlayToggleOnTooltip
                                              )
                                            : intl.formatMessage(
                                                  messages.autoPlayToggleOffTooltip
                                              )}
                                    </CoreTypography>
                                }
                            >
                                <Switch
                                    inputRef={toggleRef}
                                    checked={isResetAutoPlayEnabled}
                                    onChange={handleToggleResetAutoplay}
                                    aria-label={intl.formatMessage(
                                        messages.autoPlayNextResetAriaLabel
                                    )}
                                    data-testid="ResetAutoplayToggle"
                                />
                            </Tooltip>
                        }
                        label={
                            <FormattedMessage
                                defaultMessage="Autoplay"
                                description="Autoplay next reset switch label"
                            />
                        }
                    />
                )}
            </Stack>
        </Card>
    )
}

export default React.memo(PostResetNextResetPreview)
